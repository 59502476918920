"use client";

import React, { createContext, useContext, useState } from "react";

const context: {
	locale: string;
	popup: boolean;
	setPopup: (arg: boolean) => void;
	mobileNav: boolean;
	setMobileNav: (arg: boolean) => void;
} = {
	locale: "en",
	popup: false,
	setPopup: () => {},
	mobileNav: false,
	setMobileNav: () => {},
};

const GlobalContext = createContext(context);

export default function GlobalContextProvider({
	locale,
	children,
}: {
	locale: string;
	children: React.ReactNode;
}): React.ReactNode {
	const [popup, setPopup] = useState(false);
	const [mobileNav, setMobileNav] = useState(false);

	return (
		<GlobalContext.Provider
			value={{
				locale,
				popup,
				setPopup,
				mobileNav,
				setMobileNav,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
}

export function useGlobalContext(): typeof context {
	const context = useContext(GlobalContext);
	if (!context) {
		throw new Error("useGlobalContext must be used within a GlobalContextProvider");
	}
	return context;
}
